import './assets/css/bootstrap.custom.scss';
import { createApp } from 'vue';
import { createPinia } from 'pinia';
import { piniaCapacitorPersist } from '@/pinia-capacitor.js';
//import piniaPluginPersistedstate from 'pinia-plugin-persistedstate';
import { SplashScreen } from '@capacitor/splash-screen';
import { TextZoom } from "@capacitor/text-zoom";

import App from './App.vue';
import { useUserStore } from '@/gamestore.js';
import {Howl, Howler} from 'howler';
import './assets/css/global.css';
import './assets/css/anim.css';
import './assets/css/game-icons.css';

import "cordova-plugin-purchase";

import { Device } from '@capacitor/device';

const pinia = createPinia();
pinia.use(piniaCapacitorPersist);

const app = createApp(App);
app.use(pinia);

async function webSetup() {
  let info = await Device.getInfo();
  if ( 0 && info.operatingSystem.match(/android/i) || info.operatingSystem.match(/ios/i) ) {
    location = "/mobile.html";
  } else {
    kickoff();
  }
}

async function kickoff() {
  const userStore = useUserStore();
  await userStore.restored;

  if ( location.search == '?reset' ) {
    userStore.resetUsers();
    location = "/";
  }

  if ( Capacitor.isNativePlatform() ) {
    userStore.users.appMode = 'native';
  } else {
    userStore.users.appMode = 'web';
  }

  app.mount('#app');
}

await SplashScreen.hide();
await SplashScreen.show({
  showDuration: 1000,
  autoHide: true,
  androidScaleType: 'FIT_CENTER',
});

document.addEventListener('deviceready', function(){
  TextZoom.set({value: 1.0}); 
});

if ( Capacitor.isNativePlatform() ) {
  kickoff();
} else {
  webSetup();
}