<template>
    <div id="footer">

        <div v-if="!$parent.isPaid()" id="freebanner" class="p-2 text-uppercase">
          <a href="#" class="text-decoration-none clickfx" @click="$root.view('upgrade')">
            <div class="d-flex align-items-center justify-content-between">
              <div class="d-flex align-items-center">
                <i class="me-1 fs-2 game-icon game-icon-ringing-bell"></i>
                <span>playing free edition</span>
              </div>
              <div class="d-flex align-items-center">
                <span>upgrade now</span>
                <i class="ms-1 fs-2 game-icon game-icon-play-button"></i>
                <i class="fs-2 game-icon game-icon-play-button"></i>
              </div>
            </div>
          </a>
        </div>

        <div class="footer w-100 px-2 py-2 text-uppercase d-flex justify-content-center text-white">
            <span>Just Saying - Famous Quotations</span>
            <a href="#" class="ms-2 text-decoration-none text-white clickfx" @click="this.$root.view('about')"><i class="fs-3 game-icon game-icon-help"></i></a>
        </div>
    </div>
</template>

<script>
import { useUserStore } from '@/gamestore.js';

  export default {
    name: 'footer',
    props: {

    },
    data: () => ({   
      userStore: useUserStore(),
    }),
  }
</script>

<style>
#footer {
    position:absolute;
    bottom: calc(0px + env(safe-area-inset-top));
    width:100%;
}

#footer .footer {
  background-color: var(--bs-primary);
}

#freebanner {
  background-color: #FFFF00;
}
</style>