<template>
    <div ref="wordCloud">
        <div class="d-flex align-items-center justify-content-evenly flex-wrap">
            <div v-for="wordObj in $parent.wordCloud">
                <h2 class="m-2" v-draggable:wordSlot.move="{ data: { tk : wordObj.token } }">
                    <span class="badge rounded-pill bg-primary fw-normal text-uppercase" :class="{ 'bg-primary' : !isSelected(wordObj.token), 'bg-secondary' : isSelected(wordObj.token), 'assigned' : isAssigned(wordObj.token), 'unassigned' : !isAssigned(wordObj.token) }" :data-token="wordObj.token" @click="$parent.selectWord(wordObj.token)">
                    {{ wordObj.word }}
                    </span>
                </h2>
            </div>
        </div>
    </div>
</template>
  
<script>
import { draggable, droppable } from 'v-drag-drop';
import { useUserStore,useGameStore } from '@/gamestore.js';

  export default {
    name: 'wordcloud',
    props: ['assignWord'],
    directives: {
        draggable, droppable
    },
    data: () => ({   
        userStore: useUserStore(),
        gameStore: useGameStore(),

    }),
    methods: {
        init: function() {
        },
        dragImage: function(tk) {
            //console.log(this.$parent.wordTk(tk));
            var sourceElm = document.querySelectorAll("[data-token='" + tk + "']");
            console.log(sourceElm);

            const canvas = document.createElement("canvas");
            canvas.width = canvas.height = 50;

            const ctx = canvas.getContext("2d");
            ctx.lineWidth = 4;
            ctx.moveTo(0, 0);
            ctx.lineTo(50, 50);
            ctx.moveTo(0, 50);
            ctx.lineTo(50, 0);
            ctx.stroke();

            return canvas;
        },
        getWords: function() {
            return this.$parent.wordcloud();
        },
        isSelected: function(tk) {
            return this.$parent.assignWord.started && this.$parent.assignWord.source == tk;
        },
        isAssigned: function(tk) {
            return Object.values(this.assignWord.slots).includes(tk);
        },
    },
    mounted() {
        //document.getElementById('wordcloud').style.setProperty('--ws-scale', 1);
        this.init();
    }
  }


</script>
  
  <style>
    
    .wordcloud {
        width: 100%;
        display: flex;
        justify-content: center;
        cursor: pointer;
    }
    .assigned {
        background-color: #CCC !important;
        opacity: 0.75;
        transition: all 0.25s ease-out;
    }
    .unassigned {
        opacity: 1;
        transition: all 0.25s ease-in;
    }

  </style>
  