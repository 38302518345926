<template>

    <div class="">

        <div class="mb-4">
            <div class="fs-4 p-1 d-flex align-items-center justify-content-center bg-black text-white">
                <div class="ps-1 text-uppercase">
                    unlock the puzzles
                </div>
            </div>
        </div>

        <div class="m-4 fs-5 text-secondary">
            This free edition of Just Saying is limited to a set of 200 puzzles and {{ userStore.users.maxUsers.free }} players.
        </div>

        <div class="m-2 fs-4 text-primary">
            <h2 class="m-2">Upgrade to the full edition with a one-time purchase.</h2>
            <ul class="fs-4">
                <li>Over 3,000 puzzles and growing</li>
                <li>Create up to 10 players</li>
            </ul>
        </div>

        <div class="m-5 card text-center" v-if="$parent.IAP.title">
            <div class="card-header text-uppercase">
                Upgrade Details
            </div>
            <div class="card-body">
                <h5 class="card-title">{{ $parent.IAP.title }}</h5>
                <p class="card-text">{{ $parent.IAP.description }}</p>
                <p class="card-text">{{ $parent.IAP.pricing.price }} {{ $parent.IAP.pricing.currency }}</p>
            </div>
            <div class="card-footer text-muted">
                <button class="btn btn-primary btn-sm text-uppercase clickfx" @click="this.$parent.kickoffIAP()">Upgrade</button>
            </div>
        </div>
        <div v-else class="m-2 text-secondary">
            Uh-oh: cannot connect to the Play Store.
        </div>

        <alert-dialog ref="alertDialog"></alert-dialog>
        <confirm-dialog ref="confirmDialog"></confirm-dialog>
    </div>

</template>

<script>
import { useUserStore,useGameStore } from '@/gamestore.js';
import alertDialog from '@/components/alertdialog.vue';
import confirmDialog from '@/components/confirmdialog.vue';


export default {
    components: { 
        alertDialog, confirmDialog
    },
    data: () => ({  
        userStore: useUserStore(),
        gameStore: useGameStore(),
    }),
    methods: {

    },
    mounted() {
        this.$root.networkStatus();
        
        if ( this.IAP.owned ) {
            location.reload();
        }
    },
}

</script>

<style>

</style>