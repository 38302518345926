<template>

    <div class="userhud mb-1" :class="{ 'anim slide-out-right' : switchUserAnim==1, 'anim slide-in-left' : switchUserAnim==2 }" >
        <div class="fs-4 d-flex align-items-start justify-content-between">
            <div class="w-50 text-uppercase d-flex align-items-start flex-nowrap">
                <a class="px-1 fs-3 text-decoration-none clickfx" :style="{ color: userStore.user.styleColor }" href="#" @click="$root.view('player');" v-html="$root.avatarLib[userStore.user.avatar]"></a>
                
                <div class="d-flex flex-column align-items-left justify-content-center">
                    <a class="fs-6 text-decoration-none text-nowrap clickfx" :style="{ color: userStore.user.styleColor }" href="#" @click="$root.view('player');">{{ userStore.user.name }}</a>
                    <div class v-if="$root.calcAwards()">
                        <span v-for="awd in $root.calcAwards()">
                            <i v-for="idx in awd.qty" class="fs-5" :class="awd.icon" :style="'color: ' + this.gameStore.options.ppr[awd.ppr].color"></i>
                        </span>
                    </div>
                </div>

            </div>
            <div class="d-flex align-items-center justify-content-between flex-fill pe-1">
                <div class="d-flex  align-items-center">
                    <span class="badge rounded-pill bg-yellow text-dark">
                        {{ userStore.user.tools.shield.total }}
                    </span>
                    <i class="fs-2 game-icon game-icon-checked-shield" :class="{ 'anim pulsate-fwd text-danger' : userStore.user.tools.shield.active }" @click="activateTool(userStore.user.tools.shield)"></i>
                </div>

                <div class="d-flex  align-items-center">
                    <span class="badge rounded-pill bg-yellow text-dark">
                        {{ userStore.user.tools.wand.total }}
                    </span>
                    <i class="fs-2 game-icon game-icon-fairy-wand" :class="{ 'anim pulsate-fwd text-danger' : userStore.user.tools.wand.active }" @click="activateTool(userStore.user.tools.wand)"></i>
                </div>

                <a href="#" class="text-decoration-none text-dark" :class="{ 'clickfx' : userStore.user.totals.score }" @click="userStore.user.totals.score ? $root.view('shop') : ''">
                    <div class="d-flex align-items-center" :class="{ 'anim pulsate-fwd' : $root.isBust() }">
                            <span class="badge rounded-pill" :class="{ 'bg-danger text-white' : $root.isBust(), 'bg-yellow text-dark' : !$root.isBust() }">
                                {{  this.$root.numFormat(userStore.user.totals.score) }}
                            </span>
                            <i class="fs-2 game-icon game-icon-two-coins" :class="{ 'text-danger' : $root.isBust() }"></i>
                    </div>
                </a>
            </div>
        </div>
    </div>

    <confirm-dialog ref="confirmDialog"></confirm-dialog>
</template>

<script>
import { useUserStore,useGameStore } from '@/gamestore.js';
import confirmDialog from '@/components/confirmdialog.vue';

export default {
  name: 'userhud',
  components: { 
        confirmDialog
    },
  props: {

  },
  data: () => ({   
      userStore: useUserStore(),
      gameStore: useGameStore(),
      switchUserAnim: 0,
  }),
  methods: {
    activateTool: function(tool) {
        if ( this.gameStore.currentGame.gameboard.isStart && this.gameStore.currentGame.gameboard.isReady && tool.total > 0 ) {
            if ( tool.active ) {
                if ( tool.sound.off ) {
                    this.$root.playSfx(this.$root.sfxObj.tmp,tool.sound.off,{});
                }
                tool.active = 0;
            } else if ( !tool.active ) {
                if ( tool.sound.on ) {
                    this.$root.playSfx(this.$root.sfxObj.tmp,tool.sound.on,{});
                }
                tool.active = 1;

                if ( tool.id == 'wand' ) {
                    this.confirmWand();                    
                }
            }
        }
    },
    async confirmWand() {
        const ok = await this.$refs.confirmDialog.show({
            title: 'Solving Wand',
            message: 'Your wand will solve this clue by magic.',
            okButton: 'Nevermind',
            cancelButton: 'Use it',
        });
        // If you throw an error, the method will terminate here unless you surround it wil try/catch
        if (ok) {
            //bail
        } else {
            //ok
            this.$parent.solveGuess();
        }
    }
  },
}
</script>

<style>

</style>