<template>
    <div class="">

        <userhud ref="userhud" />

        <div class="mb-4">
            <div class="fs-4 p-1 d-flex align-items-center justify-content-center bg-black text-white">
                <div class="ps-1 text-uppercase">
                    <span v-if="isGuest()">create a player</span>
                    <span v-else>more about you</span>
                </div>
            </div>
        </div>

        <div class="px-2" style="overflow:hidden">

            <div v-if="!isGuest()" class="user-edit-wrapper bg-white" :class="{ 'anim slide-out-right' : createNewUserKickoff==1, 'anim slide-in-left' : createNewUserKickoff==2 }">
                <div class="d-flex align-items-center justify-content-between mt-3">
                    <div class="text-uppercase fs-4">Name</div>
                    <div class="w-50">
                        <input type="text" maxlength="16" class="form-control text-end clickfx"  v-on:keyup="inputUpdate($event)" v-model="userStore.user.name" />
                    </div>
                </div>
                <div class="mt-2 d-flex align-items-center justify-content-between mt-3">
                    <div class="text-uppercase fs-4">
                        <a href="#" class="text-decoration-none clickfx" @click="selectAvatar=1">Avatar</a>
                    </div>
                    <div class="fs-1">
                        <a href="#" class="text-decoration-none clickfx" v-html="$root.avatarLib[userStore.user.avatar]" @click="selectAvatar=1"></a>
                    </div>
                </div>
            </div>

            <div class="d-flex justify-content-center" v-if="selectAvatar">
                <div class="p-3 anim puff-in-center select-avatar">
                    <div class="row">
                        <div v-for="([key, value]) in Object.entries(avatarLib)" class="col-2">
                            <a class="text-dark text-decoration-none fs-1 clickfx" @click="selectedAvatar(key)" v-html="value"></a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="mt-5 text-center" v-if="isGuest()">
                <button type="button" class="btn btn-lg btn-primary text-uppercase" @click="newUser()">
                    Create New Player
                </button>
            </div>

            <div class="mt-4 d-flex align-items-center justify-content-center" v-if="!isGuest()">
                <button type="button" class="btn btn-lg btn-success text-white text-uppercase clickfx anim marquee position-relative" @click="$root.restart()">
                    <span></span><span></span><span></span><span></span>
                    Play Now
                </button>
            </div>

            <div class="mt-5 text-center" v-if="!isGuest() && !selectAvatar && Object.keys(userStore.otherUsers).length>0">
                <hr />
                <div class="mt-2 d-flex align-items-center justify-content-between mt-3">
                    <div class="text-uppercase fs-4">Switch Player</div>
                    <div class="w-50">
                        <select class="form-select" v-model="switchUserSelect" @change="switchUser($event)">
                            <option :value="0">Who?</option>
                            <option v-for="([key, value]) in Object.entries(userStore.otherUsers)" :value="key">{{ value.name }}</option>
                        </select>
                    </div>
                </div>    
            </div>

            <div class="mt-5 text-center" :class="{ 'anim jello-horizontal' : deleteUserAnim==1 }" v-if="!isGuest() && !selectAvatar && Object.keys(userStore.otherUsers).length>0">
                <div class="mt-2 d-flex align-items-center justify-content-between mt-3">
                    <div class="text-uppercase fs-4">Delete Player</div>
                    <div class="w-50">
                        <select class="form-select" v-model="deleteUserSelect" @change="deleteUser($event)">
                            <option :value="0">Who?</option>
                            <option v-for="([key, value]) in Object.entries(userStore.otherUsers)" :value="key">{{ value.name }}</option>
                        </select>
                    </div>
                </div>    
            </div>

            <div v-if="!isGuest() && !selectAvatar" class="mt-2">
                <hr />
                <div class="d-flex align-items-center justify-content-center">
                    <button type="button" class="btn btn-lg btn-primary text-uppercase" @click="newUser()">
                        Create New Player
                    </button>
                </div>
            </div>

        </div>

        <alert-dialog ref="alertDialog"></alert-dialog>
        <confirm-dialog ref="confirmDialog"></confirm-dialog>
    </div>

</template>

<script>
import { useUserStore,useGameStore } from '@/gamestore.js';
import userhud from "@/components/userhud.vue";
import alertDialog from '@/components/alertdialog.vue';
import confirmDialog from '@/components/confirmdialog.vue';
import * as randomName from '@/names.js';

export default {
  name: 'player',
  components: { 
        userhud, alertDialog, confirmDialog
    },  
  props: {
    
  },
  data: () => ({   
      userStore: useUserStore(),
      gameStore: useGameStore(),
      selectAvatar: 0,
      createNewUserKickoff: 0,
      switchUserSelect: 0,
      deleteUserSelect: 0,
      deleteUserAnim: 0,
  }),
  computed: {
    avatarLib: function() {
        return this.$root.avatarLib;
    }
  },
  mounted() {
    this.gameStore.currentGame.gameboard.isStart = 0;
  },
  methods: {
    inputUpdate: function(evt) {
        if  ( evt.which == 13 ) {
            evt.srcElement.blur();
        }
    },
    newUser: function() {
        var maxUsers = this.$parent.isPaid() ? this.userStore.users.maxUsers.paid : this.userStore.users.maxUsers.free;
        if ( Object.keys(this.userStore.users.users).length > maxUsers ) {
            this.tooManyUsers();
            return;
        }

        var userName = randomName.randomName();
        var avatarId = Object.keys(this.$root.avatarLib)[Math.floor(Math.random() * Object.keys(this.$root.avatarLib).length)];

        if ( this.isGuest() ) {
            this.userStore.newUser({name:userName,avatar:avatarId});
        } else {
            this.createNewUserKickoff = 1;
            this.userStore.newUser({name:userName,avatar:avatarId});  
            this.$root.playSfx(this.$root.sfxObj.tmp,this.$root.sfxLib.newthing,{});

            var self = this;
            setTimeout(function(){
                self.createNewUserKickoff = 2;
                setTimeout(function(){
                    self.createNewUserKickoff = 0;
                }, 500);
            }, 500);
        }
    },
    isGuest: function() {
        return false;
        return this.userStore.users.active == 1 ? 1 : 0;
    },
    selectedAvatar(id) {
        this.userStore.user.avatar = id;
        this.selectAvatar = 0;
    },
    switchUser(event) {
        if ( event.target.value == 0 ) {
            return;
        }

        this.$refs.userhud.switchUserAnim = 1;

        var self = this;
        self.$root.playSfx(self.$root.sfxObj.tmp,self.$root.sfxLib.swoosh,{});

        setTimeout(function(){
            self.userStore.users.active = event.target.value;
            self.switchUserSelect = 0;
            self.$refs.userhud.switchUserAnim = 2;

            setTimeout(function(){
                self.$refs.userhud.switchUserAnim = 0;
            },250);
        },250);
    },
    async tooManyUsers() {
        const ok = await this.$refs.alertDialog.show({
            title: 'Too Many Players',
            message: 'You need to delete a player before making another.',
        });
    },
    async deleteUser(event) {
        if ( event.target.value == 0 ) {
            return;
        }

        const ok = await this.$refs.confirmDialog.show({
            title: 'Delete Player',
            message: 'Are you sure you want to delete this player? All their progress will be lost.',
            okButton: 'Delete',
        });
        // If you throw an error, the method will terminate here unless you surround it wil try/catch
        if (ok) {
            var self = this;
            self.deleteUserAnim = 1;
            self.$root.playSfx(self.$root.sfxObj.tmp,self.$root.sfxLib.delete,{});

            setTimeout(function(){
                self.userStore.deleteUser(event.target.value);
                self.deleteUserSelect = 0;
                self.deleteUserAnim = 0;
            }, 250);    
        } else {
            //bail
            this.deleteUserSelect = 0;
        }
    },  
    async resetData() {
        const ok = await this.$refs.confirmDialog.show({
            title: 'Delete All Data',
            message: 'Are you sure you want to permanently delete all players and saved data?',
            okButton: 'Delete',
        });
        // If you throw an error, the method will terminate here unless you surround it wil try/catch
        if (ok) {
            this.userStore.resetUsers();
        } else {
            //bail
        }
    },    
  },
}
</script>

<style>
.select-avatar {
    width: 90%;
}

</style>