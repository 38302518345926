<template>

    <div class="">

        <userhud />

        <div class="mb-4">
            <div class="fs-4 p-1 d-flex align-items-center justify-content-center bg-black text-white">
                <div class="ps-1 text-uppercase">
                    spend some coin
                </div>  
            </div>
        </div>
        
        <div class="px-2">
            <div class="d-flex align-items-center justify-content-between mt-3">
                <div class="text-uppercase fs-4 d-flex align-items-center">
                    <a class="ms-2 text-decoration-none clickfx" href="#" @click="buyImmunityShield(1)">
                        <i class="fs-2 game-icon game-icon-checked-shield"></i> Immunity
                    </a>
                </div>
                <div class="text-primary text-uppercase fs-4 d-flex align-items-center" @click="buyImmunityShield(1)">
                    <span class="me-2">{{ gameStore.bonus.immunityPrice }}</span>
                    <i class="fs-1 game-icon game-icon-two-coins"></i>
                </div>
            </div>
            <div class="d-flex align-items-center justify-content-between mt-3">
                <div class="text-uppercase fs-4 d-flex align-items-center">
                    <a class="ms-2 text-decoration-none clickfx" href="#" @click="buyImmunityShield(3)">
                        <i class="fs-2 game-icon game-icon-checked-shield"></i> Immunity (3 pack)
                    </a>
                </div>
                <div class="text-primary text-uppercase fs-4 d-flex align-items-center" @click="buyImmunityShield(3)">
                    <span class="me-2">{{ Math.round(gameStore.bonus.immunityPrice * 3 * gameStore.bonus.immunityQtyDiscount) }}</span>
                    <i class="fs-1 game-icon game-icon-two-coins"></i>
                </div>
            </div>
            <div class="d-flex align-items-center justify-content-between mt-3">
                <div class="text-uppercase fs-4 d-flex align-items-center">
                    <a class="ms-2 text-decoration-none clickfx" href="#" @click="buyWand(1)">
                        <i class="fs-2 game-icon game-icon-fairy-wand"></i> Solving Wand
                    </a>
                </div>
                <div class="text-primary text-uppercase fs-4 d-flex align-items-center" @click="buyImmunityShield(1)">
                    <span class="me-2">{{ gameStore.bonus.wandPrice }}</span>
                    <i class="fs-1 game-icon game-icon-two-coins"></i>
                </div>
            </div>
            <div class="d-flex align-items-center justify-content-between mt-3">
                <div class="text-uppercase fs-4 d-flex align-items-center">
                    <a class="ms-2 text-decoration-none clickfx" href="#" @click="toggleSelectAvatarStyle">
                        <i class="fs-2 game-icon game-icon-large-paint-brush"></i> Player Color</a>
                </div>
                <div class="text-primary text-uppercase fs-4 d-flex align-items-center" @click="toggleSelectAvatarStyle">
                    <span class="me-2">{{ gameStore.bonus.userStyle }}</span>
                    <i class="fs-1 game-icon game-icon-two-coins"></i>
                </div>
            </div>
        </div>

        <div class="d-flex justify-content-center" v-if="selectAvatarStyle">
            <div class="p-3 anim puff-in-center select-avatar-style">
                <div class="row">
                    <div v-for="([key, value]) in Object.entries(avatarStyles)" class="col-2">
                        <a class="text-decoration-none fs-1 clickfx" @click="selectedAvatarStyle(key)" v-html="value"></a>
                    </div>
                </div>
            </div>
        </div>

        <div class="mt-5 text-center">
            <button type="button" class="btn btn-lg btn-success text-white text-uppercase" @click="$root.restart()">
                Play Now
            </button>
        </div>
    </div>

    <alert-dialog ref="alertDialog"></alert-dialog>
    <confirm-dialog ref="confirmDialog"></confirm-dialog>

</template>

<script>
import userhud from "@/components/userhud.vue";
import { useUserStore,useGameStore } from '@/gamestore.js';
import alertDialog from '@/components/alertdialog.vue';
import confirmDialog from '@/components/confirmdialog.vue';

function randomColor(brightness){
  function randomChannel(brightness){
    var r = 255-brightness;
    var n = 0|((Math.random() * r) + brightness);
    var s = n.toString(16);
    return (s.length==1) ? '0'+s : s;
  }
  return '#' + randomChannel(brightness) + randomChannel(brightness) + randomChannel(brightness);
}

export default {
    components: { 
        userhud, alertDialog, confirmDialog
    },
    data: () => ({  
        userStore: useUserStore(),
        gameStore: useGameStore(),
        selectAvatarStyle: 0,
        avatarStyles: {},
    }),
    computed: {

    },
    methods: {
        startGame: function(){
            this.$root.view('play');
        },
        genAvatarStyles: function() {
            var colors = [];
            for (let x = 0; x < 30; x++) {
                colors.push ( randomColor(50) );
            }

            var ar = {};
            
            for (let x = 0; x < colors.length; x++) {
                var ht = "<div class='colorSwatch my-1 cursor-pointer' style='background-color:" + colors[x] + "'></div>";
                ar[colors[x]] = ht;
            }

            return ar;
        },
        toggleSelectAvatarStyle: function() {
            if ( this.selectAvatarStyle ) {
                this.selectAvatarStyle = 0;
                return;
            } else {
                this.avatarStyles = this.genAvatarStyles();
                this.selectAvatarStyle = 1;
            }
        },
        async buyWand(qty) {

            this.selectAvatarStyle = 0;

            var price = this.gameStore.bonus.wandPrice;

            if ( price >= this.userStore.user.totals.score ) {
                const ok = await this.$refs.alertDialog.show({
                    title: 'Not enough coins',
                    message: "You don't have enough coins for this - play more and earn more!",
                });

                return;
            }

            const ok = await this.$refs.confirmDialog.show({
                title: 'Buy Solving Wand',
                message: 'This will cost you ' + price  + ' coins. OK?',
                okButton: 'No',
                cancelButton: 'Yes',
            });
            // If you throw an error, the method will terminate here unless you surround it wil try/catch
            if (ok) {
                //bail
            } else {
                //real ok
                this.userStore.user.tools.wand.total++;
                this.userStore.chargeUser( this.gameStore.bonus.wandPrice );
                this.$root.playSfx(this.$root.sfxObj.tmp,this.$root.sfxLib.buy,{});
            }
        },         
        async buyImmunityShield(qty) {

            this.selectAvatarStyle = 0;

            if ( qty > 1 ) {
                var price = Math.round( qty * this.gameStore.bonus.immunityPrice * this.gameStore.bonus.immunityQtyDiscount );
            } else {
                var price = this.gameStore.bonus.immunityPrice;
            }

            if ( price >= this.userStore.user.totals.score ) {
                const ok = await this.$refs.alertDialog.show({
                    title: 'Not enough coins',
                    message: "You don't have enough coins for this - play more and earn more!",
                });

                return;
            }

            const ok = await this.$refs.confirmDialog.show({
                title: 'Buy Immunity Shield',
                message: 'This will cost you ' + price  + ' coins. OK?',
                okButton: 'No',
                cancelButton: 'Yes',
            });
            // If you throw an error, the method will terminate here unless you surround it wil try/catch
            if (ok) {
                //bail
            } else {
                //real ok
                this.userStore.user.tools.shield.total += qty ;
                this.userStore.chargeUser( price );
                this.$root.playSfx(this.$root.sfxObj.tmp,this.$root.sfxLib.buy,{});
            }
        },        
        async selectedAvatarStyle(style) {

            if ( this.gameStore.bonus.userStyle >= this.userStore.user.totals.score ) {
                this.selectAvatarStyle = 0;

                const ok = await this.$refs.alertDialog.show({
                    title: 'Not enough coins',
                    message: "You don't have enough coins for this - play more and earn more!",
                });

                return;
            }

            const ok = await this.$refs.confirmDialog.show({
                title: 'Buy Player Color',
                message: 'This will cost you ' + this.gameStore.bonus.userStyle  + ' coins. OK?',
                okButton: 'No',
                cancelButton: 'Yes',
            });
            // If you throw an error, the method will terminate here unless you surround it wil try/catch
            if (ok) {
                //bail
                this.selectAvatarStyle = 0;
            } else {
                //real ok
                this.userStore.user.styleColor = style;
                this.selectAvatarStyle = 0;
                this.userStore.chargeUser( this.gameStore.bonus.userStyle );
                this.$root.playSfx(this.$root.sfxObj.tmp,this.$root.sfxLib.buy,{});
            }
        },
    },
    mounted() {
        this.gameStore.currentGame.gameboard.isStart = 0;
    },
}

</script>

<style>
.select-avatar-style {
    width: 90%;
}

.colorSwatch {
    height: 30px;
}
</style>
