//setup sfx files
const sfxLib = {};
sfxLib.startGame = {
    files: [
        // {
        //     file: 'startgame/Action B.fix.mp3',
        //     volume: 0.15,
        //     seek: 1, 
        // },
        {
            file: 'startgame/Justice B.fix.mp3',
            volume: 0.15,
            seek: 1,
        },
    ],
};
sfxLib.swoosh = {
    files: [
        {
            file: 'misc/Swoosh_Rope_Whip_075.fix.mp3',
            volume: 1,
        },
    ],
};
sfxLib.buy = {
    files: [
        {
            file: 'misc/COINS Collect Jackpot Win 03.fix.mp3',
            volume: 0.5,
        },
    ],
};
sfxLib.penalty = {
    files: [
        {
            file: 'boo/335906__littlerainyseasons__fail.mp3',
            volume: 0.75,
        },
        {
            file: 'boo/348270__metekavruk__oops2.mp3',
            volume: 0.75,
        },
    ],
};
sfxLib.delete = {
    files: [
        // {
        //     file: 'misc/Glitch_Factory_01_Boom_Crackle_04.fix.mp3',
        //     volume: 1,
        // },
        {
            file: 'misc/Male Warrior 2 Scream, Battle, War, Cry, Fight, Shout 3.fix.mp3',
            volume: 1,
        },
    ],
};
sfxLib.newthing = {
    files: [
        {
            file: 'misc/Harp_Glissando_10b.fix.mp3',
            volume: 0.35,
            rate: 1,
        },
    ],
};
sfxLib.toolOff = {
    files: [
        {
            file: 'misc/LV-HTIS Power Down 01.fix.mp3',
            volume: 1,
            seek: 0.25, 
        },
    ],
};
sfxLib.shieldUp = {
    files: [
        {
            file: 'startgame/Action B.fix.mp3',
            volume: 1,
            seek: 0.75, 
        },
    ],
};
sfxLib.shieldBlock = {
    files: [
        {
            file: 'misc/Weapon Shot Blaster-06.fix.mp3',
            volume: 1,
            seek: 0, 
            rate: 0.9,
        },
    ],
};
sfxLib.wandOn = {
    files: [
        {
            file: 'misc/wah_short_C_001.fix.mp3',
            volume: 0.75,
        },
    ],
};
sfxLib.wandActivate = {
    files: [
        {
            file: 'misc/Magic_Teleport.fix.mp3',
            volume: 0.75,
        },
    ],
};
sfxLib.flipBoard = {
    files: [
        {
            file: 'flipboard/Scatter Plops 04.fix.mp3',
            volume: 1,
            seek: 0, 
            loop: 1,
        },
        {
            file: 'flipboard/Riffeling_Playing_Cards_on_Cardboard_4.fix.mp3',
            volume: 1,
            seek: 0, 
            loop: 1,
        },
        {
            file: 'flipboard/StartSelect,UI,set57,camera mechanics,servo,clicks clacks,meaty,operative,forward.fix.mp3',
            volume: 1,
            seek: 0,
            rate: 2, 
            loop: 1,
        },
                {
            file: 'flipboard/Wood Rattle 10.fix.mp3',
            volume: 0.75,
            seek: 0.1, 
            loop: 1,
        },
    ],
};

sfxLib.bgTheme = {
    files: [
        // {
        //     file: 'bgtheme/Bluezone_BC0273_eternity_synth_texture_melodic_phrase_004_02.fix.mp3',
        //     volume: 0.10,
        //     seek: 0, 
        //     loop: 1,
        // },     
        {
            file: 'bgtheme/Origami Repetika - 4c Iced Tea.aac',
            volume: 0.25,
            seek: 0, 
            loop: 1,
        },         
        {
            file: 'bgtheme/Zane Little - It Takes A Hero.aac',
            volume: 0.25,
            seek: 0, 
            loop: 1,
        },
        {
            file: 'bgtheme/Zane Little - Post-Adventure Tea Party.aac',
            volume: 0.25,
            seek: 0, 
            loop: 1,
        },
        {
            file: 'bgtheme/Zane Little - Sinister Abode.aac',
            volume: 0.25,
            seek: 0, 
            loop: 1,
        },                        
    ],
};

sfxLib.wordWrong = {
    files: [
        {
            file: 'wrongword/Downer 111.fix.mp3',
            volume: 0.5,
            seek: 0, 
            rate: 2,
            loop: 0,
        },
        {
            file: 'wrongword/Fail 3a.fix.mp3',
            volume: 0.5,
            seek: 0, 
            loop: 0,
        },
        {
            file: 'wrongword/VOCAL CUTE Call Affection 03.fix.mp3',
            volume: 0.5,
            seek: 0,
            rate: 0.5, 
            loop: 0,
        },
    ],
};
sfxLib.wordRight = {
    files: [
        {
            file: 'rightword/collect_item_14.fix.mp3',
            volume: 0.5,
            seek: 0, 
            loop: 0,
        },
        {
            file: 'rightword/SUCCESS PICKUP Collect Chime 01.fix.mp3',
            volume: 0.5,
            seek: 0.25, 
            loop: 0,
        },        
    ],
};
sfxLib.winPhrase = {
    files: [
        {
            file: 'winphrase/COINS Collect Jackpot Win 03.fix.mp3',
            volume: 1,
            seek: 0, 
            loop: 0,
        }, 
        {
            file: 'winphrase/SUCCESS CHEERS Win Cute Vocal Chime 03.fix.mp3',
            volume: 1,
            seek: 0, 
            loop: 0,
        },  
        {
            file: 'winphrase/SUCCESS CHIME Bells Sparkle Tune Completion 05.fix.mp3',
            volume: 1,
            seek: 0, 
            loop: 0,
        },  
        {
            file: 'winphrase/UI_Level_Up.fix.mp3',
            volume: 1,
            seek: 0, 
            loop: 0,
        },           
    ],
};
sfxLib.winPhraseTune = {
    files: [    
        {
            file: 'winphrase/Origami Repetika - Kind Gentle Beautiful Person.mp3',
            volume: 0.25,
            seek: 0, 
            loop: 0,
        },        
    ],
};
sfxLib.bonusCharm = {
    files: [    
        {
            file: 'bonus/G4F SFX05 - Casual - MA3 sfx_voice_woohoo_02.fix.mp3',
            volume: 1.25,
            seek: 0, 
            loop: 0,
        }, 
        {
            file: 'bonus/333404__jayfrosting__cheer-2.wav.mp3',
            volume: 1.25,
            seek: 0, 
            loop: 0,
        },
        {
            file: 'bonus/345086__metrostock99__oh-yeah-low-4.wav.mp3',
            volume: 1.25,
            seek: 0, 
            loop: 0,
        },
        {
            file: 'bonus/681458__supersouper__yeah-cheering.wav.mp3',
            volume: 1.25,
            seek: 0, 
            loop: 0,
        },     
    ],
};
sfxLib.nextPuzzle = {
    files: [
        {
            file: 'nextpuzzle/POP Echo Bouncer 01.fix.mp3',
            volume: 0.25,
            seek: 0, 
            loop: 0,
        },            
    ],
};
sfxLib.bust = {
    files: [    
        {
            file: 'boo/563850__nikplaymostories__fail-trombone-wah-wah-wah-sound-effect.mp3',
            volume: 0.75,
            seek: 0.5, 
        },  
        {
            file: 'boo/335906__littlerainyseasons__fail.mp3',
            volume: 0.75,
            seek: 0.5, 
        },    
        {
            file: 'boo/353925__dr_skitz__boo.mp3',
            volume: 0.75,
            seek: 0.5, 
        },  
    ],
};
sfxLib.stamp = {
    files: [    
        {
            file: 'misc/dark impacts.fix.mp3',
            volume: 1,
        },    
    ],
};
sfxLib.clickSound = {
    files: [    
        {
            file: 'misc/LV-HTIS Beeps Synth 02.fix.mp3',
            volume: 0.5,
            seek: 0, 
            loop: 0,
        },        
    ],
};
sfxLib.IAP = {
    files: [
        {
            file: 'misc/SUCCESS CHEERS Win Cute Vocal Chime 03.fix.mp3',
            volume: 0.5,
        },
    ],
};


export default {
    sfxLib
}
