<template>

    <div class="">

        <div class="mb-4 text-center">
            <img src="@/assets/gfx/js-paythanks.png" style="width:90%" />
        </div>

        <div class="m-4 fs-4 text-primary text-center">
            You now have all game features available.
        </div>

        <div class="mt-4 d-flex align-items-center justify-content-center">
            <button type="button" class="btn btn-lg btn-success text-white text-uppercase clickfx anim marquee position-relative" @click="$root.restart()">
                <span></span><span></span><span></span><span></span>
                Play Now
            </button>
        </div>

    </div>

</template>

<script>
import { useUserStore,useGameStore } from '@/gamestore.js';

export default {
    components: { 
    },
    data: () => ({  
        userStore: useUserStore(),
        gameStore: useGameStore(),
    }),
    methods: {

    },
    mounted() {
        console.log('paythanks mounted');
        this.$root.playSfx(this.$root.sfxObj.tmp,this.$root.sfxLib.IAP,{});
    },
}

</script>

<style>
.text-shadow {
    text-shadow: 2px 2px 2px rgba(0,0,0,0.75);
}
</style>